import { GraphQLClient } from "graphql-request"
import { GraphQLClientRequestHeaders } from "graphql-request/build/cjs/types"
import * as Operations from "./bounceSdk.gen"
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query"
import { bounceClient } from "./bounceClient.client"
import * as Types from "./bounceSdk.types"
export type QueryError = Error | import("graphql-request").ClientError

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action()

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetCity(
      variables?: Types.GetCityQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetCityQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetCityQuery>(Operations.GetCityDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCity",
        "query",
      )
    },
    GetCities(
      variables?: Types.GetCitiesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetCitiesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetCitiesQuery>(Operations.GetCitiesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCities",
        "query",
      )
    },
    GetNearbyCities(
      variables: Types.GetNearbyCitiesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetNearbyCitiesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetNearbyCitiesQuery>(Operations.GetNearbyCitiesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetNearbyCities",
        "query",
      )
    },
    GetLuggageStorageSitemapCities(
      variables?: Types.GetLuggageStorageSitemapCitiesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetLuggageStorageSitemapCitiesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetLuggageStorageSitemapCitiesQuery>(
            Operations.GetLuggageStorageSitemapCitiesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "GetLuggageStorageSitemapCities",
        "query",
      )
    },
    GetCountries(
      variables?: Types.GetCountriesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetCountriesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetCountriesQuery>(Operations.GetCountriesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCountries",
        "query",
      )
    },
    RegisterUser(
      variables: Types.RegisterUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.RegisterUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.RegisterUserMutation>(Operations.RegisterUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "RegisterUser",
        "mutation",
      )
    },
    SubscribeNewsletter(
      variables: Types.SubscribeNewsletterMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.SubscribeNewsletterMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.SubscribeNewsletterMutation>(Operations.SubscribeNewsletterDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "SubscribeNewsletter",
        "mutation",
      )
    },
    LuggageStorageCommissions(
      variables: Types.LuggageStorageCommissionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.LuggageStorageCommissionsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.LuggageStorageCommissionsQuery>(
            Operations.LuggageStorageCommissionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "LuggageStorageCommissions",
        "query",
      )
    },
    GetPoi(
      variables: Types.GetPoiQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetPoiQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetPoiQuery>(Operations.GetPoiDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetPoi",
        "query",
      )
    },
    GetNearbyPois(
      variables?: Types.GetNearbyPoisQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetNearbyPoisQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetNearbyPoisQuery>(Operations.GetNearbyPoisDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetNearbyPois",
        "query",
      )
    },
    RequestAPPBySMS(
      variables: Types.RequestAppBySmsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.RequestAppBySmsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.RequestAppBySmsMutation>(Operations.RequestAppBySmsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "RequestAPPBySMS",
        "mutation",
      )
    },
    AggregateRating(
      variables?: Types.AggregateRatingQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.AggregateRatingQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.AggregateRatingQuery>(Operations.AggregateRatingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AggregateRating",
        "query",
      )
    },
    Reviews(
      variables: Types.ReviewsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.ReviewsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.ReviewsQuery>(Operations.ReviewsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Reviews",
        "query",
      )
    },
    GetStorageSpot(
      variables?: Types.GetStorageSpotQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetStorageSpotQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetStorageSpotQuery>(Operations.GetStorageSpotDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetStorageSpot",
        "query",
      )
    },
    GetStoreLocation(
      variables: Types.GetStoreLocationQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetStoreLocationQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetStoreLocationQuery>(Operations.GetStoreLocationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetStoreLocation",
        "query",
      )
    },
    GetNearbyStorageSpots(
      variables: Types.GetNearbyStorageSpotsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetNearbyStorageSpotsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetNearbyStorageSpotsQuery>(Operations.GetNearbyStorageSpotsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetNearbyStorageSpots",
        "query",
      )
    },
    GetCityStorageSpots(
      variables: Types.GetCityStorageSpotsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetCityStorageSpotsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetCityStorageSpotsQuery>(Operations.GetCityStorageSpotsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetCityStorageSpots",
        "query",
      )
    },
    GetStoresSitemap(
      variables: Types.GetStoresSitemapQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetStoresSitemapQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetStoresSitemapQuery>(Operations.GetStoresSitemapDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetStoresSitemap",
        "query",
      )
    },
    GetTags(
      variables?: Types.GetTagsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetTagsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetTagsQuery>(Operations.GetTagsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetTags",
        "query",
      )
    },
    GetServices(
      variables?: Types.GetServicesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<Types.GetServicesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<Types.GetServicesQuery>(Operations.GetServicesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "GetServices",
        "query",
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>

export const CityTranslationsFragmentDoc = `
    fragment CityTranslations on City {
  translations {
    slug {
      language
      text
    }
  }
}
    `
export const CityFragmentDoc = `
    fragment City on City {
  id
  slug
  name
  active
  priority
  paFeatured
  featured
  query
  country
  region
  luggagePrice
  lowestPrice
  prismicId
  currency
  labels {
    name
    metadata
  }
  marketingLanguages
  coordinates {
    latitude
    longitude
  }
  storeCount @include(if: $withCount)
  nearestStore @include(if: $withNearest) {
    distance
  }
  pois @include(if: $withPois) {
    id
    slug
    name
    query
    priority
    active
    marketingLanguages
    coordinates {
      latitude
      longitude
    }
    translations {
      slug {
        language
        text
      }
    }
  }
}
    `
export const LoginResponseFragmentDoc = `
    fragment LoginResponse on LoginResponse {
  accessToken
  refreshToken
  expiry
}
    `
export const MainStoreFragmentDoc = `
    fragment MainStore on StorageSpot {
  id
  nickname
  gmbPhone
  gmbLocationName
  gmbAddress {
    addressLines
    locality
    administrativeArea
    regionCode
    postalCode
  }
  coordinates {
    latitude
    longitude
  }
  city {
    name
    state
    country
  }
  address {
    street
    zipCode
  }
}
    `
export const OpeningHourFragmentDoc = `
    fragment OpeningHour on OpeningHour {
  openTime
  closeTime
  dayOfWeek
}
    `
export const OpeningHourExceptionFragmentDoc = `
    fragment OpeningHourException on OpeningHourException {
  closeTime
  closed
  startDate
  endDate
  openTime
  reason
}
    `
export const WithOpeningHoursFragmentDoc = `
    fragment WithOpeningHours on StorageSpot {
  openingHours {
    ...OpeningHour
  }
  openingHourExceptions {
    ...OpeningHourException
  }
}
    ${OpeningHourFragmentDoc}
${OpeningHourExceptionFragmentDoc}`
export const PoiTranslationsFragmentDoc = `
    fragment PoiTranslations on Poi {
  translations {
    slug {
      language
      text
    }
  }
}
    `
export const PoiFragmentDoc = `
    fragment Poi on Poi {
  id
  name
  slug
  query
  priority
  type
  active
  marketingLanguages
  boilerplateContent
  coordinates {
    latitude
    longitude
  }
  city {
    id
    slug
  }
  mainStore {
    ...MainStore
    ...WithOpeningHours
  }
  labels {
    name
    metadata
  }
  ...PoiTranslations
}
    ${MainStoreFragmentDoc}
${WithOpeningHoursFragmentDoc}
${PoiTranslationsFragmentDoc}`
export const StorageSpotFragmentDoc = `
    fragment StorageSpot on StorageSpot {
  id
  nickname
  type
  reviewCount
  rating
  slug
  price
  lowestPrice
  city {
    currency
    country
  }
  coordinates {
    latitude
    longitude
  }
}
    `
export const WithGmbFragmentDoc = `
    fragment WithGmb on StorageSpot {
  gmbAddress {
    addressLines
    locality
    administrativeArea
    regionCode
    postalCode
  }
  gmbLocationName
  gmbLocationPlaceId
  gmbPhone
}
    `
export const GetCityDocument = `
    query GetCity($slug: String, $coordinates: CoordinatesInput, $withCount: Boolean! = true, $withPois: Boolean! = false, $withNearest: Boolean! = false, $onlyActive: Boolean! = true) {
  city(slug: $slug, onlyActive: $onlyActive, coordinates: $coordinates) {
    ...City
    boilerplateContent
    name
    state
    country
    ...CityTranslations
    mainStore {
      ...MainStore
      ...WithOpeningHours
    }
  }
}
    ${CityFragmentDoc}
${CityTranslationsFragmentDoc}
${MainStoreFragmentDoc}
${WithOpeningHoursFragmentDoc}`

export const useGetCityQuery = <TData = Types.GetCityQuery, TError = QueryError>(
  variables?: Types.GetCityQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetCityQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetCityQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetCityQuery, TError, TData>({
    queryKey: variables === undefined ? ["GetCity"] : ["GetCity", variables],
    queryFn: bounceClient<Types.GetCityQuery, Types.GetCityQueryVariables>(GetCityDocument, variables),
    ...options,
  })
}

useGetCityQuery.getKey = (variables?: Types.GetCityQueryVariables) =>
  variables === undefined ? ["GetCity"] : ["GetCity", variables]

export const GetCitiesDocument = `
    query GetCities($before: String, $first: Int, $last: Int, $after: String, $orderBy: [CitySortOrder], $withCount: Boolean! = false, $withPois: Boolean! = false, $withNearest: Boolean! = false, $onlyActive: Boolean! = true) {
  cities(
    before: $before
    first: $first
    last: $last
    after: $after
    orderBy: $orderBy
    onlyActive: $onlyActive
  ) {
    edges {
      node {
        ...City
        ...CityTranslations
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${CityFragmentDoc}
${CityTranslationsFragmentDoc}`

export const useGetCitiesQuery = <TData = Types.GetCitiesQuery, TError = QueryError>(
  variables?: Types.GetCitiesQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetCitiesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetCitiesQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetCitiesQuery, TError, TData>({
    queryKey: variables === undefined ? ["GetCities"] : ["GetCities", variables],
    queryFn: bounceClient<Types.GetCitiesQuery, Types.GetCitiesQueryVariables>(GetCitiesDocument, variables),
    ...options,
  })
}

useGetCitiesQuery.getKey = (variables?: Types.GetCitiesQueryVariables) =>
  variables === undefined ? ["GetCities"] : ["GetCities", variables]

export const GetNearbyCitiesDocument = `
    query GetNearbyCities($latitude: Float!, $longitude: Float!, $first: Int = 12) {
  cities(coordinates: {latitude: $latitude, longitude: $longitude}, first: $first) {
    edges {
      node {
        id
        slug
        name
        translations {
          slug {
            language
            text
          }
        }
      }
    }
  }
}
    `

export const useGetNearbyCitiesQuery = <TData = Types.GetNearbyCitiesQuery, TError = QueryError>(
  variables: Types.GetNearbyCitiesQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetNearbyCitiesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetNearbyCitiesQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetNearbyCitiesQuery, TError, TData>({
    queryKey: ["GetNearbyCities", variables],
    queryFn: bounceClient<Types.GetNearbyCitiesQuery, Types.GetNearbyCitiesQueryVariables>(
      GetNearbyCitiesDocument,
      variables,
    ),
    ...options,
  })
}

useGetNearbyCitiesQuery.getKey = (variables: Types.GetNearbyCitiesQueryVariables) => ["GetNearbyCities", variables]

export const GetLuggageStorageSitemapCitiesDocument = `
    query GetLuggageStorageSitemapCities($first: Int, $after: String) {
  cities(first: $first, after: $after, onlyActive: true) {
    edges {
      node {
        id
        slug
        name
        priority
        marketingLanguages
        ...CityTranslations
        pois {
          id
          slug
          name
          priority
          active
          marketingLanguages
          translations {
            slug {
              language
              text
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${CityTranslationsFragmentDoc}`

export const useGetLuggageStorageSitemapCitiesQuery = <
  TData = Types.GetLuggageStorageSitemapCitiesQuery,
  TError = QueryError,
>(
  variables?: Types.GetLuggageStorageSitemapCitiesQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetLuggageStorageSitemapCitiesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetLuggageStorageSitemapCitiesQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetLuggageStorageSitemapCitiesQuery, TError, TData>({
    queryKey:
      variables === undefined ? ["GetLuggageStorageSitemapCities"] : ["GetLuggageStorageSitemapCities", variables],
    queryFn: bounceClient<
      Types.GetLuggageStorageSitemapCitiesQuery,
      Types.GetLuggageStorageSitemapCitiesQueryVariables
    >(GetLuggageStorageSitemapCitiesDocument, variables),
    ...options,
  })
}

useGetLuggageStorageSitemapCitiesQuery.getKey = (variables?: Types.GetLuggageStorageSitemapCitiesQueryVariables) =>
  variables === undefined ? ["GetLuggageStorageSitemapCities"] : ["GetLuggageStorageSitemapCities", variables]

export const GetCountriesDocument = `
    query GetCountries {
  countries {
    country
    luggagePrice
    lowestPrice
    currency
  }
}
    `

export const useGetCountriesQuery = <TData = Types.GetCountriesQuery, TError = QueryError>(
  variables?: Types.GetCountriesQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetCountriesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetCountriesQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetCountriesQuery, TError, TData>({
    queryKey: variables === undefined ? ["GetCountries"] : ["GetCountries", variables],
    queryFn: bounceClient<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>(GetCountriesDocument, variables),
    ...options,
  })
}

useGetCountriesQuery.getKey = (variables?: Types.GetCountriesQueryVariables) =>
  variables === undefined ? ["GetCountries"] : ["GetCountries", variables]

export const RegisterUserDocument = `
    mutation RegisterUser($input: RegisterUserInput!) {
  registerUser(input: $input) {
    data {
      ...LoginResponse
    }
  }
}
    ${LoginResponseFragmentDoc}`

export const useRegisterUserMutation = <TError = QueryError, TContext = unknown>(
  options?: UseMutationOptions<Types.RegisterUserMutation, TError, Types.RegisterUserMutationVariables, TContext>,
) => {
  return useMutation<Types.RegisterUserMutation, TError, Types.RegisterUserMutationVariables, TContext>({
    mutationKey: ["RegisterUser"],
    mutationFn: (variables?: Types.RegisterUserMutationVariables) =>
      bounceClient<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>(RegisterUserDocument, variables)(),
    ...options,
  })
}

export const SubscribeNewsletterDocument = `
    mutation SubscribeNewsletter($input: SubscribeNewsletterInput!) {
  subscribeNewsletter(input: $input) {
    status
  }
}
    `

export const useSubscribeNewsletterMutation = <TError = QueryError, TContext = unknown>(
  options?: UseMutationOptions<
    Types.SubscribeNewsletterMutation,
    TError,
    Types.SubscribeNewsletterMutationVariables,
    TContext
  >,
) => {
  return useMutation<Types.SubscribeNewsletterMutation, TError, Types.SubscribeNewsletterMutationVariables, TContext>({
    mutationKey: ["SubscribeNewsletter"],
    mutationFn: (variables?: Types.SubscribeNewsletterMutationVariables) =>
      bounceClient<Types.SubscribeNewsletterMutation, Types.SubscribeNewsletterMutationVariables>(
        SubscribeNewsletterDocument,
        variables,
      )(),
    ...options,
  })
}

export const LuggageStorageCommissionsDocument = `
    query LuggageStorageCommissions($cityId: String!) {
  luggageStorageCommissions(cityId: $cityId) {
    currency
    nonExclusive {
      walkInBonus {
        perNumBookings
        amount
      }
    }
    exclusive {
      walkInBonus {
        perNumBookings
        amount
      }
    }
  }
}
    `

export const useLuggageStorageCommissionsQuery = <TData = Types.LuggageStorageCommissionsQuery, TError = QueryError>(
  variables: Types.LuggageStorageCommissionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.LuggageStorageCommissionsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.LuggageStorageCommissionsQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.LuggageStorageCommissionsQuery, TError, TData>({
    queryKey: ["LuggageStorageCommissions", variables],
    queryFn: bounceClient<Types.LuggageStorageCommissionsQuery, Types.LuggageStorageCommissionsQueryVariables>(
      LuggageStorageCommissionsDocument,
      variables,
    ),
    ...options,
  })
}

useLuggageStorageCommissionsQuery.getKey = (variables: Types.LuggageStorageCommissionsQueryVariables) => [
  "LuggageStorageCommissions",
  variables,
]

export const GetPoiDocument = `
    query GetPoi($slug: String!, $onlyActive: Boolean! = true) {
  poi(slug: $slug, onlyActive: $onlyActive) {
    ...Poi
  }
}
    ${PoiFragmentDoc}`

export const useGetPoiQuery = <TData = Types.GetPoiQuery, TError = QueryError>(
  variables: Types.GetPoiQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetPoiQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetPoiQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetPoiQuery, TError, TData>({
    queryKey: ["GetPoi", variables],
    queryFn: bounceClient<Types.GetPoiQuery, Types.GetPoiQueryVariables>(GetPoiDocument, variables),
    ...options,
  })
}

useGetPoiQuery.getKey = (variables: Types.GetPoiQueryVariables) => ["GetPoi", variables]

export const GetNearbyPoisDocument = `
    query GetNearbyPois($coordinates: CoordinatesInput, $first: Int! = 12, $onlyActive: Boolean! = true, $orderBy: [PoiSortOrder]) {
  pois(
    coordinates: $coordinates
    first: $first
    onlyActive: $onlyActive
    orderBy: $orderBy
  ) {
    edges {
      node {
        ...PoiTranslations
        id
        name
        slug
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
}
    ${PoiTranslationsFragmentDoc}`

export const useGetNearbyPoisQuery = <TData = Types.GetNearbyPoisQuery, TError = QueryError>(
  variables?: Types.GetNearbyPoisQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetNearbyPoisQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetNearbyPoisQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetNearbyPoisQuery, TError, TData>({
    queryKey: variables === undefined ? ["GetNearbyPois"] : ["GetNearbyPois", variables],
    queryFn: bounceClient<Types.GetNearbyPoisQuery, Types.GetNearbyPoisQueryVariables>(
      GetNearbyPoisDocument,
      variables,
    ),
    ...options,
  })
}

useGetNearbyPoisQuery.getKey = (variables?: Types.GetNearbyPoisQueryVariables) =>
  variables === undefined ? ["GetNearbyPois"] : ["GetNearbyPois", variables]

export const RequestAppBySmsDocument = `
    mutation RequestAPPBySMS($phone: String!) {
  requestAppLinkText(input: {phone: $phone}) {
    status
  }
}
    `

export const useRequestAppBySmsMutation = <TError = QueryError, TContext = unknown>(
  options?: UseMutationOptions<Types.RequestAppBySmsMutation, TError, Types.RequestAppBySmsMutationVariables, TContext>,
) => {
  return useMutation<Types.RequestAppBySmsMutation, TError, Types.RequestAppBySmsMutationVariables, TContext>({
    mutationKey: ["RequestAPPBySMS"],
    mutationFn: (variables?: Types.RequestAppBySmsMutationVariables) =>
      bounceClient<Types.RequestAppBySmsMutation, Types.RequestAppBySmsMutationVariables>(
        RequestAppBySmsDocument,
        variables,
      )(),
    ...options,
  })
}

export const AggregateRatingDocument = `
    query AggregateRating($cityId: String) {
  aggregateRating(cityId: $cityId) {
    count
    averageRating
  }
}
    `

export const useAggregateRatingQuery = <TData = Types.AggregateRatingQuery, TError = QueryError>(
  variables?: Types.AggregateRatingQueryVariables,
  options?: Omit<UseQueryOptions<Types.AggregateRatingQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.AggregateRatingQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.AggregateRatingQuery, TError, TData>({
    queryKey: variables === undefined ? ["AggregateRating"] : ["AggregateRating", variables],
    queryFn: bounceClient<Types.AggregateRatingQuery, Types.AggregateRatingQueryVariables>(
      AggregateRatingDocument,
      variables,
    ),
    ...options,
  })
}

useAggregateRatingQuery.getKey = (variables?: Types.AggregateRatingQueryVariables) =>
  variables === undefined ? ["AggregateRating"] : ["AggregateRating", variables]

export const ReviewsDocument = `
    query Reviews($cityId: String, $first: Int!, $minRating: Int, $orderBy: [ReviewSortOrder], $preferredLanguage: String, $withFeedback: Boolean, $withTranslatedFeedback: Boolean!) {
  reviews(
    cityId: $cityId
    first: $first
    minRating: $minRating
    orderBy: $orderBy
    preferredLanguage: $preferredLanguage
    withFeedback: $withFeedback
  ) {
    edges {
      node {
        feedback
        rating
        id
        user {
          firstName
          lastName
        }
        completedAt
        store {
          city {
            id
            slug
            name
          }
        }
        translatedFeedback @include(if: $withTranslatedFeedback)
      }
    }
  }
}
    `

export const useReviewsQuery = <TData = Types.ReviewsQuery, TError = QueryError>(
  variables: Types.ReviewsQueryVariables,
  options?: Omit<UseQueryOptions<Types.ReviewsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.ReviewsQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.ReviewsQuery, TError, TData>({
    queryKey: ["Reviews", variables],
    queryFn: bounceClient<Types.ReviewsQuery, Types.ReviewsQueryVariables>(ReviewsDocument, variables),
    ...options,
  })
}

useReviewsQuery.getKey = (variables: Types.ReviewsQueryVariables) => ["Reviews", variables]

export const GetStorageSpotDocument = `
    query GetStorageSpot($slug: String) {
  store(slug: $slug) {
    distance
    ...StorageSpot
    ...WithOpeningHours
  }
}
    ${StorageSpotFragmentDoc}
${WithOpeningHoursFragmentDoc}`

export const useGetStorageSpotQuery = <TData = Types.GetStorageSpotQuery, TError = QueryError>(
  variables?: Types.GetStorageSpotQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetStorageSpotQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetStorageSpotQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetStorageSpotQuery, TError, TData>({
    queryKey: variables === undefined ? ["GetStorageSpot"] : ["GetStorageSpot", variables],
    queryFn: bounceClient<Types.GetStorageSpotQuery, Types.GetStorageSpotQueryVariables>(
      GetStorageSpotDocument,
      variables,
    ),
    ...options,
  })
}

useGetStorageSpotQuery.getKey = (variables?: Types.GetStorageSpotQueryVariables) =>
  variables === undefined ? ["GetStorageSpot"] : ["GetStorageSpot", variables]

export const GetStoreLocationDocument = `
    query GetStoreLocation($slug: String, $language: String!, $reviewsCount: Int = 5) {
  store(slug: $slug) {
    ...StorageSpot
    ...WithGmb
    ...WithOpeningHours
    address {
      street
      zipCode
    }
    city {
      ...CityTranslations
      id
      coordinates {
        latitude
        longitude
      }
      country
      currency
      name
      query
      slug
      state
    }
    marketingPageEnabled
    reviews(
      first: $reviewsCount
      withFeedback: true
      preferredLanguage: $language
      minRating: 5
    ) {
      edges {
        node {
          completedAt
          feedback
          id
          rating
          translatedFeedback
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
}
    ${StorageSpotFragmentDoc}
${WithGmbFragmentDoc}
${WithOpeningHoursFragmentDoc}
${CityTranslationsFragmentDoc}`

export const useGetStoreLocationQuery = <TData = Types.GetStoreLocationQuery, TError = QueryError>(
  variables: Types.GetStoreLocationQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetStoreLocationQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetStoreLocationQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetStoreLocationQuery, TError, TData>({
    queryKey: ["GetStoreLocation", variables],
    queryFn: bounceClient<Types.GetStoreLocationQuery, Types.GetStoreLocationQueryVariables>(
      GetStoreLocationDocument,
      variables,
    ),
    ...options,
  })
}

useGetStoreLocationQuery.getKey = (variables: Types.GetStoreLocationQueryVariables) => ["GetStoreLocation", variables]

export const GetNearbyStorageSpotsDocument = `
    query GetNearbyStorageSpots($latitude: Float!, $longitude: Float!, $first: Int, $minRating: Float) {
  stores(
    coordinates: {latitude: $latitude, longitude: $longitude}
    first: $first
    minRating: $minRating
  ) {
    edges {
      node {
        ...StorageSpot
      }
    }
  }
}
    ${StorageSpotFragmentDoc}`

export const useGetNearbyStorageSpotsQuery = <TData = Types.GetNearbyStorageSpotsQuery, TError = QueryError>(
  variables: Types.GetNearbyStorageSpotsQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetNearbyStorageSpotsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetNearbyStorageSpotsQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetNearbyStorageSpotsQuery, TError, TData>({
    queryKey: ["GetNearbyStorageSpots", variables],
    queryFn: bounceClient<Types.GetNearbyStorageSpotsQuery, Types.GetNearbyStorageSpotsQueryVariables>(
      GetNearbyStorageSpotsDocument,
      variables,
    ),
    ...options,
  })
}

useGetNearbyStorageSpotsQuery.getKey = (variables: Types.GetNearbyStorageSpotsQueryVariables) => [
  "GetNearbyStorageSpots",
  variables,
]

export const GetCityStorageSpotsDocument = `
    query GetCityStorageSpots($cityId: String!, $first: Int!, $marketingPageEnabled: Boolean = false) {
  stores(
    cityId: $cityId
    first: $first
    marketingPageEnabled: $marketingPageEnabled
  ) {
    edges {
      node {
        ...StorageSpot
        city {
          currency
          country
          slug
        }
      }
    }
  }
}
    ${StorageSpotFragmentDoc}`

export const useGetCityStorageSpotsQuery = <TData = Types.GetCityStorageSpotsQuery, TError = QueryError>(
  variables: Types.GetCityStorageSpotsQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetCityStorageSpotsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetCityStorageSpotsQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetCityStorageSpotsQuery, TError, TData>({
    queryKey: ["GetCityStorageSpots", variables],
    queryFn: bounceClient<Types.GetCityStorageSpotsQuery, Types.GetCityStorageSpotsQueryVariables>(
      GetCityStorageSpotsDocument,
      variables,
    ),
    ...options,
  })
}

useGetCityStorageSpotsQuery.getKey = (variables: Types.GetCityStorageSpotsQueryVariables) => [
  "GetCityStorageSpots",
  variables,
]

export const GetStoresSitemapDocument = `
    query GetStoresSitemap($first: Int!, $marketingPageEnabled: Boolean = true) {
  stores(marketingPageEnabled: $marketingPageEnabled, first: $first) {
    edges {
      node {
        slug
        city {
          slug
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `

export const useGetStoresSitemapQuery = <TData = Types.GetStoresSitemapQuery, TError = QueryError>(
  variables: Types.GetStoresSitemapQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetStoresSitemapQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetStoresSitemapQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetStoresSitemapQuery, TError, TData>({
    queryKey: ["GetStoresSitemap", variables],
    queryFn: bounceClient<Types.GetStoresSitemapQuery, Types.GetStoresSitemapQueryVariables>(
      GetStoresSitemapDocument,
      variables,
    ),
    ...options,
  })
}

useGetStoresSitemapQuery.getKey = (variables: Types.GetStoresSitemapQueryVariables) => ["GetStoresSitemap", variables]

export const GetTagsDocument = `
    query GetTags($first: Int) {
  tags(first: $first) {
    edges {
      node {
        key
        name
      }
    }
  }
}
    `

export const useGetTagsQuery = <TData = Types.GetTagsQuery, TError = QueryError>(
  variables?: Types.GetTagsQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetTagsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetTagsQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetTagsQuery, TError, TData>({
    queryKey: variables === undefined ? ["GetTags"] : ["GetTags", variables],
    queryFn: bounceClient<Types.GetTagsQuery, Types.GetTagsQueryVariables>(GetTagsDocument, variables),
    ...options,
  })
}

useGetTagsQuery.getKey = (variables?: Types.GetTagsQueryVariables) =>
  variables === undefined ? ["GetTags"] : ["GetTags", variables]

export const GetServicesDocument = `
    query GetServices($first: Int) {
  services(first: $first) {
    edges {
      node {
        key
        name
      }
    }
  }
}
    `

export const useGetServicesQuery = <TData = Types.GetServicesQuery, TError = QueryError>(
  variables?: Types.GetServicesQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetServicesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<Types.GetServicesQuery, TError, TData>["queryKey"]
  },
) => {
  return useQuery<Types.GetServicesQuery, TError, TData>({
    queryKey: variables === undefined ? ["GetServices"] : ["GetServices", variables],
    queryFn: bounceClient<Types.GetServicesQuery, Types.GetServicesQueryVariables>(GetServicesDocument, variables),
    ...options,
  })
}

useGetServicesQuery.getKey = (variables?: Types.GetServicesQueryVariables) =>
  variables === undefined ? ["GetServices"] : ["GetServices", variables]
